.applicationWrapper {
  margin-top: 20px;
  background: #f6faff;
  width: fit-content;
  .applicationTableContainer {
    border-collapse: collapse;
    td {
      white-space: nowrap;
    }

    thead {
      border-radius: 2px;
      background: #f6faff;
      padding: 21px;
      th {
        color: var(--secondary-black, #616161);
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        padding: 21px 0;
        text-align: left;
        padding-left: 20px;
      }
      th:last-child {
        padding-right: 21px;
      }
    }

    tbody {
      tr {
        td {
          color: var(--secondary-deep-ocean-100, #2c4761);
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          padding: 20px 0;
          padding-left: 21px;
          .viewJd {
            border-radius: 4px;
            border: 1.5px solid var(--black-high, #212121);
            color: var(--black-high, #212121);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;

            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            padding: 8px 16px;
            cursor: pointer;
          }
          .sendButton {
            margin-left: 25px;
            background: #34a853;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            color: var(--white-high, #fff);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            border: none;
            outline: none;
            padding: 8px 16px;
            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            cursor: pointer;
          }
        }
        td:last-child {
          padding-right: 21px;
        }
      }
      tr:nth-child(odd) {
        // border: 1px solid;
        background: #fff;
      }
      tr:nth-child(even) {
        background: #f8f8f8;
      }
    }

    td.viewJd {
      cursor: pointer;
    }

    td.sendInvite {
    }
  }
}

.invites {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropdownInvite {
  top: 30px;
  width: 100px;
  padding: 30px;
  color: #414141;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.04),
    -3px -3px 4px 0px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  & > div:nth-child(1) {
    margin-bottom: 24px;
  }
}
