.CandidateWrapper {
  margin-top: 20px;
  background: #f6faff;
  width: fit-content;
  td {
    white-space: nowrap;
  }
  .applicationTableContainer {
    border-collapse: collapse;

    thead {
      border-radius: 2px;
      background: #f6faff;
      padding: 21px;
      th {
        color: var(--secondary-black, #616161);
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        padding: 21px 0;
        text-align: left;
        padding-left: 20px;
      }
      th:last-child {
        padding-right: 21px;
      }
    }

    tbody {
      tr {
        td {
          color: var(--secondary-deep-ocean-100, #2c4761);
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          padding: 20px 0;
          padding-left: 21px;
          .viewJd {
            border-radius: 4px;
            border: 1.5px solid var(--black-high, #212121);
            color: var(--black-high, #212121);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;

            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            padding: 8px 16px;
            cursor: pointer;
          }

          .sendButton {
            // margin-left: 25px;
            background: #34a853;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            color: var(--white-high, #fff);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            border: none;
            outline: none;
            padding: 8px 16px;
            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            cursor: pointer;
          }
          .viewProfile {
            border-radius: 4px;
            border: 1.5px solid var(--black-high, #212121);
            color: var(--black-high, #212121);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;

            /* font/button/large/sentence */
            font-family: "Inter";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            padding: 8px 16px;
          }
          // }
        }
        .endButtons {
          display: flex;
          flex-direction: column;
          gap: 12px;
          & > button {
            min-width: 126px;
            cursor: pointer;
          }
        }
        .skills {
          max-width: 200px;
        }
        td:last-child {
          padding-right: 21px;
        }
      }
      tr:nth-child(odd) {
        // border: 1px solid;
        background: #fff;
      }
      tr:nth-child(even) {
        background: #f8f8f8;
      }
    }

    td.viewJd {
      cursor: pointer;
    }

    td.sendInvite {
    }
  }
}

.availibility {
  position: relative;
  padding: 30px 40px;
  min-width: 550px;
  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
  }
  .title1 {
    color: #000;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
    margin-bottom: 42px;
  }
  .title2 {
    color: #000;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    margin-bottom: 4px;
  }
  .dropdown {
    width: 211px;
    height: 48px;
    margin-bottom: 38px;
    // border-radius: 4px;
    // border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
  }

  .myMenuClassName,
  .myControlClassName {
    color: var(--black-high, #212121);

    /* font/subtitle2/semibold */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
  }
  .date {
    // width: 211px;
    // height: 48px;
    outline: none;
    color: var(--black-high, #212121);
    padding: 13px 16px;
    /* font/subtitle2/semibold */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
    // line-height: 24px; /* 171.429% */
  }
  .update {
    border-radius: 4px;
    background: var(--black-high, #212121);
    padding: 12px 16px;
    width: 211px;
    color: var(--white-high, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* font/button/large/sentence */
    font-family: "Inter";
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    margin-top: 32px;
  }
}
